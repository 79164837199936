<template>
    <div class="random-cases-section" v-if="randomCases && randomCases.length">
        <div class="wrapper">
            <div class="">
                <div class="slider-header">
                    <div class="left">
                        <!-- <div class="top">
                            <div class="new-badge">{{ $t(`New!`) }}</div>
                        </div> -->
                        <div class="bottom">
                            <h2 class="slider-title">{{ $t(`Packs of random`) }}</h2>
                            <i class="info-icon" v-tooltip="{
        content: tooltipContent,
        html: true,
        triggers: ['click'],
        placement: 'top',
        autoHide: true,
        theme: 'random-cases-tooltip'
    }">
                                <img :src="require('@/assets/img/tooltip.svg')" alt="">
                            </i>
                        </div>
                    </div>
                    <div class="right">
                        <router-link to="/products/cases" class="link">{{ $t(`View all`) }}</router-link>
                    </div>
                </div>
            </div>
            <div class="random-cases-slider">
                <swiper :modules="swiperModules"
                    :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
                    :slidesPerView="'auto'" :spaceBetween="20" :slidesPerGroup="slidesToScroll()" @swiper="onSwiper">
                    <swiper-slide v-for="(card, index) in randomCases" :key="index" class="card" :data-slug="card.slug"
                        @click="handleClick(card)">
                        <div class="card-wrapper" :class="card.type">
                            <div class="card-content">
                                <div class="card-header">
                                    <h3>{{ card.name }}</h3>
                                    <p v-html='card.description'></p>
                                </div>
                                <div class="price-badge">{{ card.price }} {{ currencySymbol }}</div>
                                <div class="card-image-container">
                                    <div class="ellipse-background" :class="card.type"></div>
                                    <img :src="card.image_url" :alt="card.name" class="card-image">
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default {
    name: 'RandomCasesSlider',
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        currencySymbol: {
            type: String,
            required: true
        },
        currencyCode: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            swiperModules: [Navigation],
            tooltipContent: `
          <h2 class="tooltip-title">Random Packs</h2>
          <p class="tooltip-description">
            Please be advised that items obtained from these cases are distributed on a purely random basis. The value of the items you receive can vary significantly, generally ranging from equal to the cost of the case itself to potentially slightly exceeding the case's value. It's important to understand that due to the random nature of item distribution, we cannot guarantee the exact value or specific items you will receive from any given case. By purchasing a case, you acknowledge and accept these terms and conditions of the platform. If you have any questions or concerns, please contact our customer support before making a purchase.
          </p>
        `,
            randomCases: [],
            isDragging: false,
            swiper: null,

        }
    },
    computed: {
        //   ...mapGetters("app", ["currentCurrencySymbol", "currentCurrency"]),
    },
    watch: {
        currencyCode() {
            this.fetchRandomCases();
        },
        watch: {
            slidesToScroll() {
                this.$nextTick(() => {
                    this.handleWindowSizeChange();
                });
            },
        },
    },
    created() {
        this.fetchRandomCases();
        window.addEventListener("resize", this.handleWindowSizeChange);
    },
    mounted() {
    },
    unmounted() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        handleWindowSizeChange() {
            if (this.swiper) {
                // this.swiper.params.slidesPerView = this.slidesToScroll();
                this.swiper.params.slidesPerGroup = this.slidesToScroll();
                this.swiper.update();
            }
        },
        slidesToScroll() {
            let containerWidth;
            if (innerWidth >= 1200) {
                containerWidth = 1200; // fixed container width for large screens
            } else {
                containerWidth = innerWidth - 32;
            }
            const cardWidth = 303;
            const gap = 20;
            const slidesToShow = Math.floor((containerWidth + gap) / (cardWidth + gap));
            return slidesToShow;
        },
        handleClick(item) {
            this.$emit('goToProduct', item);
        },
        async fetchRandomCases() {
            try {
                const response = await this.$http.get(process.env.VUE_APP_API + 'packs' + '?currency=' + this.currencyCode);
                if (response.status === 200) {
                    this.randomCases = response.data.payload;
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    this.$parent.openSignInModal();
                }
            }
        },
    }
}
</script>

<style lang='scss' scoped>
.random-cases-section {
    // margin-top: 160px;

    .slider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 69px;
        margin-bottom: 40px;

        .left {

            .bottom {
                display: flex;
                gap: 10px;
            }

            .slider-title {
                font-weight: 600;
                font-size: calc(clamp(1.313rem, 0.83rem + 2.411vw, 3rem));
                color: white;
            }

            .info-icon {
                height: 100%;
            }

            .info-icon img {
                display: block;
                cursor: pointer;
            }
        }

        .link {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #fff;
        }

    }
}


.swiper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.swiper-slide {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
    color: #EC7633;
    // width: 40px; 
    border: 1px solid #EC7633; 
    // height: 477px; 
    // translate: 0 30px; 
    // top: 0; 
    // backdrop-filter: blur(5px); 

    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: #16171C;

    &::after {
        font-size: 16px;
    }



    @media (min-width: 1024px) {
        &:hover {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    &.swiper-button-disabled {
        // visibility: hidden; 
        pointer-events: initial;
        cursor: not-allowed;
    }
}

.swiper-button-prev {
    left: -60px;
}

.swiper-button-next {
    right: -60px;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.random-cases-slider {
    width: 100%;
    height: 509px;
    position: relative;

}

.slider-header {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 303px;
    min-width: 100%;
    height: 477px;
    padding: 16px 0;
    border-radius: 12px;
    position: relative;
    transition: all 0.15s ease;
    // margin: 0 16px;
    cursor: pointer;
    border: 1px solid #FFFFFF;
    background: #30323B;

    &:hover {
        width: 320px;
        height: 509px;
        border: 1px solid #6D82F2;
    }
}

.card-wrapper .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.card-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        font-size: 28px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 4px;
        color: #fff;
        // height: 38px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 290px;
        text-align: center;
    }

    p {
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        height: 46px;
        max-height: 46px;
        color: #fff;
        opacity: 0.5;
        text-align: center;
        max-width: 218px;
        text-wrap: wrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 12px;
    }
}

.price-badge {
    /* position: absolute; */
    /* top: 10px; */
    /* left: 10px; */
    width: max-content;
    height: 39px;
    background: #6D82F2;
    color: white;
    border-radius: 4px;
    z-index: 2;
    padding: 8px 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    margin-bottom: 12px;
}

.card-image-container {
    position: relative;
    width: 292px;
    height: 292px;
    pointer-events: none;
    /* margin: 0 auto; */
}

.card-image {
    position: relative;
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

// .new-badge {
//     background: #EC7633;
//     color: white;
//     text-transform: uppercase;
//     border-radius: 24px;
//     width: 64px;
//     height: 24px;
//     padding: 4px 16px;

//     font-size: 12px;
//     line-height: 16px;
//     text-wrap: nowrap;
// }

@media (max-width: 670px) {

    .card-wrapper {
        margin: 0;
        width: 288px;
    }

}

@media (max-width: 1200px) {
    .swiper-button-prev {
    left: 0px;
}

.swiper-button-next {
    right: 0px;
}
}
</style>